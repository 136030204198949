var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"devis","fluid":"","tag":"section"}},[_c('h1',{staticClass:"font-weight-light pb-7 headline text-center"},[_vm._v(" Devis en "),_c('v-select',{staticClass:"year-selector",attrs:{"items":_vm.years,"hide-details":""},on:{"input":_vm.goToYear},model:{value:(_vm.annee),callback:function ($$v) {_vm.annee=$$v},expression:"annee"}})],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-tabs',{attrs:{"background-color":"transparent","slider-color":"white"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.months),function(month,index){return _c('v-tab',{key:index,attrs:{"to":("#" + (index+1))}},[_vm._v(" "+_vm._s(month)+" ")])}),1)]},proxy:true}])},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher un devis","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"py-2"}),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((12),function(i){return _c('v-tab-item',{key:i,attrs:{"value":("" + i)}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-data-table',{staticClass:"table-devis",attrs:{"headers":_vm.headers,"items":_vm.devis.filter(function (f) { return new Date(f.dateEmission).getMonth()+1 == i; }),"search":_vm.search,"sort-by":'dateEmission',"sort-desc":false},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reference)+" "),(item.envoyee && !item.payee && new Date().toISOString() > item.dateEcheance)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"#ebc538","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v("Échéance dépassée")])]):_vm._e()]}},{key:"item.envoyee",fn:function(ref){
var item = ref.item;
return [(item.envoyee)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(!item.envoyee)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.payee",fn:function(ref){
var item = ref.item;
return [(item.payee)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(!item.payee)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e()]}},{key:"item.clientDestinataire",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clientDestinataire.nomCommercial)+" ")]}},{key:"item.cours",fn:function(ref){
var item = ref.item;
return [(item.cours)?_c('span',[_vm._v(" "+_vm._s(item.cours.name)+" ")]):_c('span',[_c('i',[_vm._v("Aucun")])])]}},{key:"item.dateEmission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.dateEmission).toLocaleString().substring(0, 5))+" ")]}},{key:"item.dateEnvoi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateEnvoi ? new Date(item.dateEnvoi).toLocaleString().substring(0, 5) : '')+" ")]}},{key:"item.etat",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.displayEtat(item.etat))}})]}},{key:"item.montantHT",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.toEuro(item.montantHT)))])]}},{key:"item.montantTVA",fn:function(ref){
var item = ref.item;
return [_c('i',[_vm._v(_vm._s(_vm.toEuro(item.montantTVA)))])]}},{key:"item.montantTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toEuro(item.montantTotal))+" ")]}},{key:"item.lienFacture",fn:function(ref){
var item = ref.item;
return [(item.lienFacture)?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.downloadFacture(item.reference)}}},[_vm._v(" mdi-download ")]):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"pa-4",attrs:{"elevation":"3","color":"primary","dark":""}},[_c('v-card-title',[_vm._v("Total HT : "+_vm._s(_vm.toEuro(_vm.calculTotalHT(i))))]),_c('v-card-text',[_c('div',{staticClass:"white--text my-4 subtitle-1"},[_vm._v(" Total TTC : "+_vm._s(_vm.toEuro(_vm.calculTotalTTC(i)))+" ")])])],1)],1)],1)],1)}),1)],1),_c('div',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{attrs:{"color":"primary","x-large":"","to":{ name: 'CreerDevis'}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle")]),_vm._v(" Créer un nouveau devis ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }