<template>
  <v-container
    id="devis"
    fluid
    tag="section"
  >
    <h1 class="font-weight-light pb-7 headline text-center">
      Devis en
      <v-select
        class="year-selector"
        v-model="annee"
        :items="years"
        @input="goToYear"
        hide-details
      />
    </h1>

    <base-material-card
      color="primary"
      class="px-5 py-3"
    >
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher un devis"
        single-line
        hide-details
      />
      <div class="py-2" />
      <template v-slot:heading>
        <v-tabs
          v-model="tabs"
          background-color="transparent"
          slider-color="white"
        >
          <v-tab
            v-for="(month, index) in months"
            :key="index"
            :to="`#${index+1}`"
          >
            {{ month }}
          </v-tab>
        </v-tabs>
      </template>

      <v-tabs-items
        v-model="tabs"
        class="transparent"
      >
        <v-tab-item
          v-for="i in 12"
          :key="i"
          :value="`${i}`"
        >
          <v-row>
            <v-col
              cols="12"
              md="9"
            >
              <v-data-table
                class="table-devis"
                :headers="headers"
                :items="devis.filter((f) => new Date(f.dateEmission).getMonth()+1 == i)"
                :search="search"
                :sort-by="'dateEmission'"
                :sort-desc="false"
                @click:row="rowClick"
              >
                <template v-slot:[`item.reference`]="{ item }">
                  {{ item.reference }}
                  <v-tooltip
                    bottom
                    v-if="item.envoyee && !item.payee && new Date().toISOString() > item.dateEcheance"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-1"
                        color="#ebc538"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span>Échéance dépassée</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.envoyee`]="{ item }">
                  <v-icon
                    class="ml-1"
                    color="green"
                    v-if="item.envoyee"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    class="ml-1"
                    color="red"
                    v-if="!item.envoyee"
                  >
                    mdi-close-circle
                  </v-icon>
                </template>
                <template v-slot:[`item.payee`]="{ item }">
                  <v-icon
                    class="ml-1"
                    color="green"
                    v-if="item.payee"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    class="ml-1"
                    color="red"
                    v-if="!item.payee"
                  >
                    mdi-close-circle
                  </v-icon>
                </template>
                <template v-slot:[`item.clientDestinataire`]="{ item }">
                  {{ item.clientDestinataire.nomCommercial }}
                </template>
                <template v-slot:[`item.cours`]="{ item }">
                  <span v-if="item.cours">
                    {{ item.cours.name }}
                  </span>
                  <span v-else>
                    <i>Aucun</i>
                  </span>
                </template>
                <template v-slot:[`item.dateEmission`]="{ item }">
                  {{ new Date(item.dateEmission).toLocaleString().substring(0, 5) }}
                </template>
                <template v-slot:[`item.dateEnvoi`]="{ item }">
                  {{ item.dateEnvoi ? new Date(item.dateEnvoi).toLocaleString().substring(0, 5) : '' }}
                </template>
                <template v-slot:[`item.etat`]="{ item }">
                  <div v-html="displayEtat(item.etat)"></div>
                </template>
                <template v-slot:[`item.montantHT`]="{ item }">
                  <b>{{ toEuro(item.montantHT) }}</b>
                </template>
                <template v-slot:[`item.montantTVA`]="{ item }">
                  <i>{{ toEuro(item.montantTVA) }}</i>
                </template>
                <template v-slot:[`item.montantTotal`]="{ item }">
                  {{ toEuro(item.montantTotal) }}
                </template>
                <template v-slot:[`item.lienFacture`]="{ item }">
                  <v-icon
                    v-if="item.lienFacture"
                    @click.stop="downloadFacture(item.reference)"
                  >
                    mdi-download
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-card
                elevation="3"
                color="primary"
                dark
                class="pa-4"
              >
                <v-card-title>Total HT : {{ toEuro(calculTotalHT(i)) }}</v-card-title>
                <v-card-text>
                  <div class="white--text my-4 subtitle-1">
                    Total TTC : {{ toEuro(calculTotalTTC(i)) }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>

    <div
      class="d-flex justify-end mb-3"
    >
      <v-btn
        color="primary"
        x-large
        :to="{ name: 'CreerDevis'}"
      >
        <v-icon class="mr-2">mdi-plus-circle</v-icon>
        Créer un nouveau devis
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        search: '',
        annee: '',
        tabs: '' + (new Date().getMonth() + 1),
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        years: [],
        headers: [
          {
            sortable: true,
            text: 'Réf.',
            value: 'reference',
          },
          {
            sortable: true,
            text: 'Client',
            value: 'clientDestinataire',
          },
          {
            sortable: true,
            text: 'Cours',
            value: 'cours',
          },
          {
            sortable: true,
            text: 'Date émission',
            value: 'dateEmission',
          },
          {
            sortable: true,
            text: 'Date envoi',
            value: 'dateEnvoi',
          },
          {
            sortable: false,
            text: 'État',
            value: 'etat',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Montant HT',
            value: 'montantHT',
            align: 'right',
          },
          {
            sortable: false,
            text: 'TVA',
            value: 'montantTVA',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Total TTC',
            value: 'montantTotal',
            align: 'right',
          },
        ],
        devis: [],
      }
    },
    created () {
      this.annee = this.$route.params.year;
      this.years = this.allFromThisYearTo(2018);
      this.getDevisFromYear();
    },

    methods: {

      rowClick(item, row) {
        this.$router.push({ name: 'DevisDetail', params: { id: item.id } });
      },

      updateRoute(route) {
        this.$router.push({ path: route })
      },

      goToYear() {
        this.$router.push({ name: 'Devis', params: { year: this.annee }, hash: '#1' });
      },

      getDevisFromYear() {
        this.axios.get('/devis/annee/' + this.annee)
          .then((res) => {
            this.devis = [...res.data];
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération des devis', type: 'warn' });
          });
      },

      displayEtat(etat) {
        switch (etat) {
          case 'NOUVEAU':
            return '<span class="badge-chips-small badge-gray">EN ATTENTE</span>';
          case 'ENVOYE':
            return '<span class="badge-chips-small badge-blue">ENVOYÉ</span>';
          case 'ACCEPTE':
            return '<span class="badge-chips-small badge-green">ACCEPTÉ</span>';
          case 'REFUSE':
            return '<span class="badge-chips-small badge-red">REFUSÉ</span>';
        }
      },

      downloadFacture(reference) {
        this.axios.get('/facturesSortantes/' + reference + '/downloadPDF', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', reference + '.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadEmargementDisabled = false;
          })
          .catch(e => {
            // this.setSnack('Erreur lors du téléchargement de la facture (code ' + e + ')');
            this.downloadEmargementDisabled = false;
          });
      },

      calculTotalHT(i) {
        const devisThisMonth = this.devis.filter((f) => new Date(f.dateEmission).getMonth() + 1 === parseInt(i));
        let totalHT = 0;
        devisThisMonth.forEach(devis => {
          for (const key in devis) {
            if (key === 'montantHT') {
              totalHT += devis[key];
            }
          }
        });
        return totalHT;
      },

      calculTotalTTC(i) {
        const devisThisMonth = this.devis.filter((f) => new Date(f.dateEmission).getMonth() + 1 === parseInt(i));
        let totalTTC = 0;
        devisThisMonth.forEach(devis => {
          for (const key in devis) {
            if (key === 'montantTotal') {
              totalTTC += devis[key];
            }
          }
        });
        return totalTTC;
      },

      allFromThisYearTo(lastYear) {
        const res = [];
        let year = new Date().getFullYear();
        while (lastYear <= year) {
          res.push(String(year));
          year--;
        }
        return res;
      },

      toEuro(num) {
        if (isNaN(num)) {
          num = 0;
        }
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(num);
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),

    },

    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.annee = this.$route.params.year;
          this.getInvoicesFromYear();
        }
      },
    },

  }
</script>

<style scoped>
  .table-devis >>> tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  .table-devis >>> tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  .no-underline {
    text-decoration: none;
  }

  .year-selector {
    font-size: 1em;
    display: inline-block;
    margin-left: 0.1em;
    width: 3.6em;
  }

  .year-selector >>> .v-select__selection--comma {
    overflow: unset;
  }

  .table-devis >>> .badge-chips-small {
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    font-weight: bold;
  }

  .table-devis >>> .badge-gray {
    background-color: #a6a8ae;
    color: #fff;
  }

  .table-devis >>> .badge-blue {
    background-color: #47a8d2;
    color: #fff;
  }

  .table-devis >>> .badge-green {
    background-color: #4CAF50;
    color: #fff;
  }

  .table-devis >>> .badge-red {
    background-color: #e93162;
    color: #fff;
  }
</style>
